import Vue from "vue";
import VueCustomElement from "vue-custom-element";
import App from "./App.vue";
import { beforeCreated } from "./plugins/initialiser";

Vue.use(VueCustomElement);

type Options = {
  ffToken: string;
  width?: string;
  height?: string;
  config?: DeepPartial<IWidgetConfiguration>;
  selector?: string;
  shadow?: boolean;
  entityId?: string;
  applicantReference?: string;
};
declare global {
  interface Window {
    frankieFinancial: {
      initialiseOnboardingWidget: (options: Options) => void;
    };
  }
}

export const frankieFinancial: Window["frankieFinancial"] = {
  initialiseOnboardingWidget(options: Options) {
    const componentName = "ff-onboarding-widget";
    addThirdPartyIntegrations(options);
    setAttrs(options);
    if (!window.customElements.get(componentName)) registerComponent(componentName, options);
  },
};
window.frankieFinancial = frankieFinancial;

async function addThirdPartyIntegrations(options: Options) {
  const { config } = options;

  if (!config?.disableThirdPartyAnalytics) {
    await addHotjar();
  }
}

async function registerComponent(componentName: string, options: Options) {
  const { injectedCssTagID = "", injectedCss = "" } = options?.config ?? {};
  // if necessary, fetches contents of link tag
  const injectedStyles = injectedCssTagID ? await extractEmbeddedCss(injectedCssTagID) : injectedCss;

  Vue.use(beforeCreated);
  Vue.customElement(componentName, (App.prototype.constructor as any).options, {
    shadow: options?.shadow ?? process.env.VUE_APP_SHADOW !== "false",
    beforeCreateVueInstance(root) {
      const el = root.el as Element;
      const rootNode = el.getRootNode();
      if (rootNode instanceof ShadowRoot) {
        (root as any).shadowRoot = rootNode;
      } else {
        (root as any).shadowRoot = document.head;
      }
      return root;
    },
    shadowCss: injectedStyles ?? "",
  });
}
async function extractEmbeddedCss(id: string): Promise<string | null> {
  const tag = document.querySelector(`#${id}`);
  if (tag?.tagName?.toLowerCase() === "link") return extractCssFromLinkElement(tag as HTMLLinkElement);
  if (tag?.tagName?.toLowerCase() === "style") return extractCssFromStyleElement(tag as HTMLStyleElement);

  console.error(`FrankieOne: Style tag #${id} wasn't found in document.`);
  return null;
}
function extractCssFromStyleElement(el: HTMLStyleElement): string | null {
  return el?.textContent ?? null;
}
async function extractCssFromLinkElement(el: HTMLLinkElement): Promise<string | null> {
  const url = el.href;
  try {
    const response = await fetch(url, {
      method: "GET",
    });
    return response.text();
  } catch (e) {
    console.error(`There was a problem fething stylesheet for id ${el.id}`);
    console.error(e);
    return null;
  }
}

function setAttrs(options: Options) {
  const { ffToken, width, height, config, applicantReference = "", entityId = "", selector = "ff-onboarding-widget" } = options;
  const widget = document.querySelector(selector);
  if (!widget) return;

  if (ffToken) widget.setAttribute("ff", ffToken);
  if (height) widget.setAttribute("height", height);
  if (width) widget.setAttribute("width", width);
  widget.setAttribute("config", encodeURI(JSON.stringify(config)));
  widget.setAttribute("applicant-reference", applicantReference);
  widget.setAttribute("entity-id", entityId);
}

function addHotjar() {
  const scriptElement = document.createElement("script");
  scriptElement.type = "text/javascript";
  scriptElement.id = "hotjar";
  const scriptTextNode = document.createTextNode(`
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2844777,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `);
  scriptElement.appendChild(scriptTextNode);
  document.head.appendChild(scriptElement);
  return new Promise((r) => (scriptElement.onload = r));
}
