import { Action, Getter } from "@/store/types";
import { isProfileEmpty, mkElectronicProfile } from "@/utils/recipes";
import {
  ConfigurationWrapper,
  countryData,
  Document,
  Event,
  EventFactory,
  FrankieApiClient,
  GetPhraseFunction,
  GetPhraseOptions,
  IMachineCredential,
  mkHtmlSanitiser,
  Organisation
} from "@frankieone/shared";
import { DeviceCheckDetails } from "@frankieone/shared/dist/types";
import { detect } from "detect-browser";
import { defineModule } from "direct-vuex";
import { Loader, LoaderOptions } from "google-maps";
import { get } from "lodash";
import { moduleActionContext } from "../..";
import parseConfiguration, { type } from "../../../utils/configurationParser";
import { findDocumentConfigInList } from "../../../utils/documentFactory";

const mkSessionActionContext = (original: any) =>
  moduleActionContext(original, mod);

const getDeviceType = (screenWidthClass) => {
  switch (screenWidthClass) {
    case "ff-mobile":
      return "mobile";
    case "ff-tablet":
      return "tablet";
    default:
      return "desktop";
  }
};
interface IAuthenticationPayload extends IMachineCredential {
  referrer?: string | null;
}
type GetFeatureFunction = (key: string) => boolean;
type ConfigKey = keyof IWidgetConfiguration | string;
type PhrasesKey = keyof typeof import("../../../locales/phrases.json") | string;
export interface SessionStoreModule {
  state: {
    organisation: Organisation | null;
    configuration: ConfigurationWrapper | null;
    frankie: FrankieApiClient | null;
    isGoogleApiLoaded: boolean;
    isPreloaded: boolean;
    eventFactory: EventFactory<eventNames> | null;
    countries: Document["country"][];
    features: Record<string, boolean>;
    deviceCheckDetails: DeviceCheckDetails;
  };
  getters: {
    isAuthenticated: Getter<boolean>;
    organisationData: Getter<Organisation | null>;
    frankie: Getter<FrankieApiClient | null>;
    config: Getter<<T = any>(path?: string, mapper?: (v: any) => T) => T>;
    documentConfiguration: Getter<
      (idType: TSupportedDocuments, country: Country) => DocConfigBody | null
    >;
    phrase: Getter<GetPhraseFunction>;
    isPreloaded: Getter<boolean>;
    countriesList: Getter<Document["country"][]>;
    feature: Getter<GetFeatureFunction>;
    getDeviceCheckDetails: Getter<DeviceCheckDetails>;
  };
  actions: {
    authenticate: Action<IAuthenticationPayload, Promise<void>>;
    setToken: Action<string, Promise<void>>;
    initializeConfiguration: Action<any, Promise<ConfigurationWrapper>>;
    initializeFrankieApi: Action;
    initializeApplicantProfile: Action;
    initializeGoogleApi: Action<void, Promise<void>>;
    initializeViews: Action;
    setPreloaded: Action;
    setCountryList: Action;
    initializeEventsFactory: Action<{ sessionId: string; customerId: string }>;
    dispatchEvent: Action<
      { eventName: eventNames; payload?: Event["data"] },
      Promise<void>
    >;
  };
}

/**
 * STATE
 */
const STATE: SessionStoreModule["state"] = {
  organisation: null,
  frankie: null,
  configuration: null,
  isGoogleApiLoaded: false,
  isPreloaded: false,
  eventFactory: null,
  countries: [],
  features: {},
  deviceCheckDetails: {},
};

/**
 * GETTERS
 */
type GetPhraseOptionsExtended = Omit<GetPhraseOptions, "sanitiseHtml"> & {
  sanitiseHtml?: GetPhraseOptions["sanitiseHtml"] & {
    extraAllowedTags?: string[];
  };
};
type TAnyToGeneric<T> = (v: any) => T;
const idFunc = (v) => v;
const GETTERS: SessionStoreModule["getters"] = {
  countriesList: (state) => state.countries,
  organisationData: (state) => state.organisation,
  feature: (state) => (key) => {
    // check for DL document number feature flags within features and create an array of the states required
    const allFeatures = state.features;
    const makeRequiredDocumentNumberFeature = () => {
      const statesWithRequiredDocumentNumber: string[] = [];
      if (allFeatures["driversLicenceCardNumberRequired"])
        statesWithRequiredDocumentNumber.push(
          "ACT",
          "NSW",
          "SA",
          "TAS",
          "WA",
          "NT"
        );
      if (allFeatures["qldDlCardNumberRequired"])
        statesWithRequiredDocumentNumber.push("QLD");
      if (allFeatures["vicDlCardNumberRequired"])
        statesWithRequiredDocumentNumber.push("VIC");

      return statesWithRequiredDocumentNumber;
    };
    // if a request comes through asking for required states return states array, else return specific feature requested
    if (key === "statesThatRequireDocNumber")
      return makeRequiredDocumentNumberFeature();
    const feature = state.features[key];
    return feature;
  },
  frankie: (state) => state.frankie,
  config:
    (state) =>
    <T = any>(path = "", mapper: TAnyToGeneric<T> = idFunc): T => {
      const value = state.configuration?.get(path)?.v();
      return mapper(value);
    },
  isAuthenticated: (state) =>
    Boolean(state.configuration) && Boolean(state.organisation),
  phrase:
    (state) =>
    (key?: string, options: GetPhraseOptionsExtended = {}) => {
      if (!key) return "";

      const { isMandatory = false, sanitiseHtml = false } = options;
      const phrases = state.configuration.get("phrases").v();
      const getPhrase = (key: string) => get(phrases, key, "");

      let phrase = getPhrase(key);
      const mandatorySuffix = getPhrase("common.mandatory_field") ?? "";
      const sanitiserOptions = (() => {
        if (typeof sanitiseHtml !== "object") return {};
        const { extraAllowedTags = [], ...sanitiserOptions } =
          sanitiseHtml as Record<string, unknown>;
        const defaultAllowedTags = mkHtmlSanitiser.defaults.allowedTags;
        const allowedTags = defaultAllowedTags.concat(
          extraAllowedTags as string[]
        );
        sanitiserOptions.allowedTags = allowedTags;

        return sanitiserOptions;
      })();

      const sanitiser = mkHtmlSanitiser(sanitiserOptions);

      if (sanitiseHtml) phrase = sanitiser(phrase);
      if (isMandatory) phrase += ` ${mandatorySuffix}`;

      return phrase;
    },
  isPreloaded: (state) => state.isPreloaded,
  documentConfiguration: (state, getters) => (idType, country) => {
    return findDocumentConfigInList(
      getters.config("documentTypes"),
      idType,
      country
    );
  },
  getDeviceCheckDetails: (state) => state.deviceCheckDetails,
};

/**
 * ACTIONS
 */
export const ACTIONS: SessionStoreModule["actions"] = {
  setCountryList(context) {
    type Country3Char = string;
    const priorityCountries = ["AUS", "NZL", "GBR", "USA", "CHN"];
    const extractAlpha3Code = (cdata: any): Country3Char => cdata.alpha3Code;
    const isRealCountry3CharCode = (char3: Country3Char) =>
      countryData.some((c) => extractAlpha3Code(c) === char3);
    const isNotPriorityCountry = (cdata: any) =>
      !priorityCountries.includes(extractAlpha3Code(cdata));

    const { state, rootGetters } = mkSessionActionContext(context);
    const hasCountries = rootGetters.config("acceptedCountries", type.nBool);
    let resolvedCountryCodes: string[] = [];
    if (hasCountries) {
      const config = rootGetters.config(
        "acceptedCountries",
        type.array(type.string)
      ) as string[];
      resolvedCountryCodes = config.filter(isRealCountry3CharCode);
    } else {
      const nonPriorityCountries: string[] = countryData
        .filter(isNotPriorityCountry)
        .map(extractAlpha3Code);
      resolvedCountryCodes = [...priorityCountries, ...nonPriorityCountries];
    }
    state.countries = resolvedCountryCodes;
  },
  async authenticate(context, payload: IAuthenticationPayload) {
    const { state } = mkSessionActionContext(context);
    const { referrer, ...credentials } = payload;

    if (!state.frankie) {
      throw new Error(
        "Frankie client hasn't been initialised. Did you miss config.frankieBackendUrl?"
      );
    }

    const { organisation } = await state.frankie.login(credentials, {
      referrer,
    });
    state.organisation = organisation;

    if (organisation?.name && state.configuration) {
      const organisationName = new ConfigurationWrapper().initialize({
        organisationName: state.organisation?.name,
      });
      state.configuration = state.configuration.merge(organisationName);
    }
  },
  async setToken(context, token: string) {
    const { state } = mkSessionActionContext(context);
    if (!state.frankie) {
      throw new Error(
        "Frankie client hasn't been initialised. Did you miss config.frankieBackendUrl?"
      );
    }

    const { frankie } = state;
    const { organisation, configuration } = (await frankie.setToken(token, {
      checkValidity: true,
    }))!;
    state.organisation = organisation;
    state.features = configuration.get("features").v();
  },
  async initializeConfiguration(
    context,
    config: any
  ): Promise<ConfigurationWrapper> {
    const { state, dispatch } = mkSessionActionContext(context);
    const withDefaults = parseConfiguration(config);
    const configuration = new ConfigurationWrapper().initialize(withDefaults);
    state.configuration = configuration;
    // initialize api
    dispatch.initializeFrankieApi();
    // initialize google api
    await dispatch.initializeGoogleApi();
    // initialize paths
    dispatch.setCountryList();

    return configuration;
  },
  initializeFrankieApi(context) {
    const { state, getters } = mkSessionActionContext(context);
    const { config } = getters;
    const frankieApiUrl = config("frankieBackendUrl");
    state.frankie = new FrankieApiClient(frankieApiUrl, "smart-ui");
  },
  initializeEventsFactory(
    context,
    options: { sessionId: string; customerId: string }
  ) {
    const { state, rootState } = mkSessionActionContext(context);
    const deviceType = getDeviceType(rootState.system.screenWidthClass);

    const initialData = {
      customerId: options.customerId,
      sessionId: options.sessionId,
      channel: "smart-ui",
      version: process.env.VUE_APP_SMART_UI_VERSION ?? "",
      deviceType,
      browser: (detect()?.name as string) ?? "",
    };

    const eventFactory = new EventFactory<eventNames>(initialData);
    state.eventFactory = eventFactory;
  },
  initializeApplicantProfile(context) {
    const { rootState, getters } = mkSessionActionContext(context);
    const { config } = getters;
    const checkProfileConfig = config("checkProfile");
    const { applicant } = rootState.personal;

    const shouldPreloadProfile =
      checkProfileConfig === "auto" && !isProfileEmpty(applicant.profile);
    const profile = shouldPreloadProfile
      ? applicant.profile!
      : checkProfileConfig;
    applicant.profile = mkElectronicProfile(profile);
  },
  async dispatchEvent(
    context,
    event: { eventName: eventNames; payload?: Event["data"] }
  ) {
    const { state, rootState, rootGetters } = mkSessionActionContext(context);
    const { eventFactory } = rootState.session;

    const { eventName, payload = {} } = event;
    const extendedPayload = {
      applicant: rootState.personal.applicant,
      documents: rootState.documents.documentsList,
      checkResults: rootState.checks.checkResults,
    };
    const decoratedWindow = decorateWindowDispatchEventWith(extendedPayload);
    const eventsClient = state.frankie?.getEventsClient({
      emitToWindow: decoratedWindow,
    });

    // case it's demo, dont submit event to service, simply dispatch it locally
    // also in case there's some issue initialising event service

    const eventObject: Event = eventFactory!.makeEvent(eventName, payload);
    if (!rootGetters.isDemo) {
      await eventsClient!.dispatchEvent(eventObject).catch(console.warn);
    } else if (typeof decoratedWindow?.CustomEvent !== "undefined") {
      const customEvent = new CustomEvent(eventName, { detail: eventObject });
      decoratedWindow.dispatchEvent(customEvent);
    }
  },
  async initializeGoogleApi(context) {
    const { getters, state } = mkSessionActionContext(context);
    const { config } = getters;
    try {
      const googleAPIKey = config("googleAPIKey");
      if (!googleAPIKey) {
        setTimeout(() => (state.isGoogleApiLoaded = false)); // to guarantee watchers are triggered after view created hook
      } else {
        const loaderOptions: LoaderOptions = { libraries: ["places"] };
        const loader = new Loader(googleAPIKey, loaderOptions);
        await loader.load();
        setTimeout(() => (state.isGoogleApiLoaded = true)); // to guarantee watchers are triggered after view created hook
      }
    } catch (error) {
      setTimeout(() => (state.isGoogleApiLoaded = false)); // to guarantee watchers are triggered after view created hook
      console.error(
        new Error(
          `Error loading google API. Address autocomplete will be skipped. ${error}`
        )
      );
    }
  },
  initializeViews(context) {
    const { getters, rootDispatch } = mkSessionActionContext(context);
    const { config } = getters;

    const welcomeScreen = config("welcomeScreen");
    const htmlContent = config("welcomeScreen.htmlContent");

    // optionally display welcome screen
    if (welcomeScreen !== false && htmlContent !== false) {
      rootDispatch.addView("welcome");
    }
    // always load initial view and it will decide what steps to add and maybe even skip initial forms altogether
    rootDispatch.addView("initial");
    // summary screen to trigger checks
    rootDispatch.addView("summary");
  },
  setPreloaded({ state }) {
    state.isPreloaded = true;
  },
};

const mod = defineModule({
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
});

export default mod;

function decorateWindowDispatchEventWith(payload: object) {
  const decorateDispatchWithExtraPayload =
    (original: Function) => (e: CustomEvent) => {
      const { detail, type } = e;
      const event = new CustomEvent(type, {
        detail: {
          ...detail,
          ...payload,
        },
      });
      return original(event);
    };
  const handler = {
    get(t, property) {
      const value = t[property];
      if (property === "dispatchEvent") {
        return decorateDispatchWithExtraPayload(value);
      }
      return value;
    },
  };
  return new Proxy(window, handler);
}
