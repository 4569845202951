












import { Component, Vue } from "vue-property-decorator";
import { Document, LoadingScreen } from "@frankieone/shared";
import OutcomeMessage from "@/components/OutcomeMessage.vue";
import { mkViewFromViewOutcome, ViewOutcome } from "@/utils/checks";
import { type } from "../utils/configurationParser";
import addScript from "@/utils/addScript";

const isWidgetLoaded = () => (window as any)?.frankieFinancialOnfido;
const initialiseOnfidoWidget = (configObject) => {
  const { applicantId } = configObject;
  if (!applicantId) console.log("Missing applicant id to initialise onfido");
  if (isWidgetLoaded())
    (window as any).frankieFinancialOnfido.initialiseOnfidoWidget(configObject);
};
const externalIdvDocTypes: Document["idType"][] = [
  "PASSPORT",
  "DRIVERS_LICENCE",
  "NATIONAL_ID",
];
@Component({
  components: {
    OutcomeMessage,
    LoadingScreen,
  },
})
export default class ExternalIdv extends Vue {
  isLoadingScript = false;

  get loadingText() {
    const idvConfig = this.$store.direct.getters.config(
      "idScanVerification"
    ) as IWidgetConfiguration["idScanVerification"];
    if (typeof idvConfig === "object") {
      const phrases = idvConfig?.language?.phrases;
      return {
        title: phrases?.loading_screen_label,
        subTitle: phrases?.loading_screen_label_2,
      };
    }
    return {
      title: "Please wait...",
    };
  }

  idvComplete(error?: any) {
    const nextViewData = { ...this.nextViewData };
    if (error) {
      console.error(error);
      nextViewData.routeData.manualIdvFailed = true;
    }
    this.dispatchCustomIdvEvent(error);
    this.$store.direct.dispatch.addView(this.nextViewData);
    this.$store.direct.dispatch.nextView();
  }

  dispatchCustomIdvEvent(error?: any) {
    const { CustomEvent } = window;
    if (CustomEvent) {
      const errorData = error ? { idvError: error?.detail } : {};
      const idvEventName = error
        ? "FF_EXTERNAL_IDV_CHECK_FAILED"
        : "FF_EXTERNAL_IDV_CHECK_COMPLETED";
      const idvOutcome = new CustomEvent(idvEventName, {
        detail: {
          applicant: this.applicantAndDocuments.applicant,
          documents: this.applicantAndDocuments.documents ?? [],
          ...errorData,
        },
      });
      window.dispatchEvent(idvOutcome);
    }
  }

  get loadingScreenStyles(): { minHeight: string } {
    return {
      minHeight: "600px",
    };
  }

  get applicantAndDocuments() {
    return this.$store.direct.getters.personalAndDocuments;
  }

  get nextViewData() {
    return mkViewFromViewOutcome(this.nextView);
  }

  get currentView() {
    return this.$store.direct.getters.currentView;
  }

  get nextView(): ViewOutcome {
    return this.currentView.data.nextView as ViewOutcome;
  }

  get fontFamily(): string {
    return window?.getComputedStyle(this.$el)?.fontFamily || "Sans-Serif";
  }

  get showIdScanVerification(): boolean {
    const { config, getDocumentOfType } = this.$store.direct.getters;
    const hasIdScanVerification = config("idScanVerification", type.bool);
    const hasDigitalDriversLicence = getDocumentOfType(
      "DRIVERS_LICENCE",
      "extraData.digital_licence"
    );
    const hasExpectedIDVDocuments = this.documentTypes.some((type) =>
      externalIdvDocTypes.includes(type)
    );

    return (
      !hasDigitalDriversLicence &&
      hasIdScanVerification &&
      hasExpectedIDVDocuments
    );
  }

  get saveOnly(): boolean {
    const { config } = this.$store.direct.getters;
    const saveOnly = config("saveOnly", type.bool);
    return saveOnly;
  }

  get documentTypes(): Document["idType"][] {
    const allDocuments = this.$store.direct.getters.selectedDocuments;
    const documentTypes = Array.from(
      new Set(allDocuments.map((d) => d.idType))
    );
    return documentTypes;
  }

  get idScanVerificationConfig(): IWidgetConfiguration["idScanVerification"] &
    object {
    const configOrBoolean =
      this.$store.direct.getters.config("idScanVerification");
    const config = configOrBoolean === true ? {} : configOrBoolean;
    return config;
  }

  get onfidoWidgetConfiguration() {
    const documentTypes = this.documentTypes.filter((type) =>
      externalIdvDocTypes.includes(type)
    );
    const frankieBackendUrl =
      this.$store.direct.getters.config("frankieBackendUrl");
    return {
      ...this.idScanVerificationConfig,
      mode: this.environmentMode ?? "production",
      frankieBackendUrl,
      documentTypes,
      supportTwoDocuments: false,
      successScreen: false,
      failureScreen: false,
      fontFamily: this.fontFamily,
      disableThirdPartyAnalytics: this.hasDisabledAnalytics,
      enableLiveDocumentCapture: false,
      saveOnly: false, // this.saveOnly always false because we want onfido to run ocr checks so its results will show on portal
    };
  }

  get onfidoMinHeight(): string {
    return "600px";
  }

  get onfidoWidgetInitialisationObject() {
    const token = this.$store.direct.getters.frankie?.frankieToken ?? null;
    const applicantId = this.$store.direct.getters.applicantDetails.entityId;

    return {
      width: "100%",
      height: this.onfidoMinHeight || "100%",
      token,
      referrer: "*://*/*",
      applicantId,
      mountingHtmlElement: this.$refs.onfidoWidget,
      config: this.onfidoWidgetConfiguration,
    };
  }

  get onfidoWidgetUrl() {
    const version = this.idScanVerificationConfig.releaseVersion;
    return process.env.VUE_APP_ONFIDO_WIDGET_URL?.replace(
      "/latest/",
      `/${version}/`
    );
  }

  async mounted() {
    const isConfigured = () => this.showIdScanVerification;
    this.isLoadingScript = true;
    if (isConfigured()) await addScript(this.onfidoWidgetUrl!);
    this.isLoadingScript = false;
    if (isConfigured() && isWidgetLoaded())
      initialiseOnfidoWidget(this.onfidoWidgetInitialisationObject);
  }

  get hasDisabledAnalytics(): boolean {
    return !!this.$store.direct.getters.config(
      "disableThirdPartyAnalytics",
      type.nBool
    );
  }

  created() {
    if (!this.showIdScanVerification) {
      const error = "No documents to scan";
      this.idvComplete(error);
    } else if (!this.onfidoWidgetUrl) {
      const error = "No url to reach the IDV widget";
      this.idvComplete(error);
    }
  }
}
