



































































import {
  Component,
  Vue as VueClass,
  Prop,
  Watch,
} from "vue-property-decorator";
import { LoadingScreen } from "@frankieone/shared";
import { type } from "./utils/configurationParser";
import RouterFrame from "./router/RouterFrame.vue";
import Unauthorized from "./views/Unauthorized.vue";
import ConfigurationError from "./views/ConfigurationError.vue";
import store from "./store";
import { initialiseConfiguration } from "./plugins/initialiser";

type Size = { width: string; height: string };
const assignSize = (element: HTMLElement, { width, height }: Size) => {
  if (!element) return;
  element.style.width = width;
  element.style.height = height;
  element.style.display = "block";
};
const FULL_SIZE = "FULL";
const AUTO_SIZE = "AUTO";
@Component({
  name: "Widget",
  store: store.original,
  components: {
    RouterFrame,
    LoadingScreen,
  },
})
export default class App extends VueClass {
  @Prop({ default: () => JSON.stringify({}) }) config: string;

  @Prop({ default: null }) applicantReference: string | null;

  @Prop({ default: null }) entityId: string | null;

  @Prop({ required: process.env.NODE_ENV === "production" }) ff: string;

  @Prop({ default: FULL_SIZE }) width: string;

  @Prop({ default: FULL_SIZE }) height: string;

  // parsedConfig: DeepPartial<IWidgetConfiguration> | null | unknown= null;

  parsedApplicantReference: string | null = null;

  token: string | null = null;

  isInitialised = false;

  hasErrors = false;

  get sizingClasses(): [string, string] {
    let widthClass = "fixed-width";
    let heightClass = "fixed-height";

    if (this.width === FULL_SIZE) {
      widthClass = "full-width";
    }
    if (this.height === FULL_SIZE) {
      heightClass = "full-height";
    }
    if (this.width === AUTO_SIZE) {
      widthClass = "auto-width";
    }
    if (this.height === AUTO_SIZE) {
      heightClass = "auto-height";
    }

    return [widthClass, heightClass];
  }

  errorComponent: null | Vue.Component = null;

  get widgetSize(): { width: string; height: string } {
    const configWidth = this.width;
    const configHeight = this.height;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    let theWidth = configWidth;
    let theHeight = configHeight;

    const matches = (a: string | number, b: string) =>
      String(a).match(new RegExp(b, "i"));

    if (matches(configWidth, FULL_SIZE)) {
      const screen = this.screenWidthPx ?? screenWidth;
      theWidth = `${screen}px`;
    }
    if (matches(configHeight, FULL_SIZE)) {
      const screen = this.screenHeightPx ?? screenHeight;
      theHeight = `${screen}px`;
    }
    if (matches(configHeight, AUTO_SIZE)) {
      theHeight = "auto";
    }
    if (matches(configWidth, AUTO_SIZE)) {
      theWidth = "auto";
    }

    return { width: theWidth, height: theHeight };
  }

  get isAuthenticated(): boolean {
    const isDemo = (() => {
      const { config } = this.$store.direct.getters;
      if (!config) return false;
      return config("mode", type.string) === "demo";
    })();
    return isDemo || this.$store.direct.getters.isAuthenticated;
  }

  get theProps() {
    return [
      this.config,
      this.applicantReference,
      this.ff,
      this.width,
      this.height,
    ];
  }

  get parsedConfig(): DeepPartial<IWidgetConfiguration> {
    if (!this.config) return {};
    let config: DeepPartial<IWidgetConfiguration> = {};
    try {
      config = JSON.parse(
        decodeURI(this.config)
      ) as DeepPartial<IWidgetConfiguration>;
    } catch (e) {
      console.error(e);
      console.log(
        "There was an issue loading the configuration for Frankie Financial's Smart UI configuration. Defaults are being used."
      );
    }
    return config;
  }

  getHostElement(): HTMLElement {
    const rootNode = this.$el.getRootNode();
    if (rootNode instanceof ShadowRoot) {
      return rootNode.host as HTMLElement;
    }

    return this.$el.parentElement!;
  }

  @Watch("widgetSize", { deep: true })
  onWidgetSizeChange() {
    assignSize(this.getHostElement(), this.widgetSize);
  }

  mounted() {
    assignSize(this.getHostElement(), this.widgetSize);
  }

  @Watch("theProps", { deep: true, immediate: true })
  onThePropsChange() {
    this.isInitialised = false;
    try {
      const applicantReference = String(this.applicantReference ?? "");
      const entityId = this.entityId ?? "";
      // we don't use parsedApplicantReference anywhere??
      this.parsedApplicantReference = applicantReference;
      this.token = this.ff;

      initialiseConfiguration({
        config: this.parsedConfig,
        ff: this.ff,
        applicantReference,
        entityId,
        // sizing: {
        //   widthParameter: this.width,
        //   heightParameter: this.height,
        //   widthApplied: this.widgetSize.width,
        //   heightApplied: this.widgetSize.height,
        //   widthClass: this.sizingClasses[0],
        //   heightClass: this.sizingClasses[1]
        // }
      })
        .then(() => (this.isInitialised = true))
        .catch((e) => {
          const error = e as TWigetError;
          this.hasErrors = true;
          switch (error.type) {
            case "configuration":
            case "not_found":
              this.errorComponent = ConfigurationError; // 'Need new screen';
              break;
            case "unauthorized":
              this.errorComponent = Unauthorized;
              break;
            default:
              break;
          }
        });
    } catch (e) {
      this.hasErrors = true;
      console.error(e);
    }
  }

  async destroyed() {
    const outcome = new CustomEvent("FF_ONBOARDING_WIDGET_DESTROYED");
    window.dispatchEvent(outcome);
    await this.$store.direct.dispatch.dispatchEvent({ eventName: "DESTROY" });
  }

  refresh() {
    // eslint-disable-next-line no-unused-expressions
    window?.location?.reload();
  }
}
